html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: athelas, georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-top: 0;
}

a {
  color: #000;
}

input[type="search"] {
  -webkit-appearance: none;
}

.app {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.app-content {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.container {
  max-width: 750px;
}

.container-small {
  max-width: 700px;
}

.input,
.select,
.textarea {
  background-color: #fff;
  font-size: 16px;
  border-radius: 5px;
}

.rounded {
  border-radius: 5px;
}

.rounded-left {
  border-radius: 5px 0 0 5px;
}

.rounded-right {
  border-radius: 0 5px 5px 0;
}

.measure {
  max-width: 30em;
}

.select-skinny {
  height: 2rem;
}

.bg-light {
  background-color: #f5f5f5;
}

.result {
  border-left: 8px solid #f5f5f5;
}

.result-loading {
  border-color: rgba(206, 217, 224, 0.2);
  -webkit-animation: skeleton-border-glow 1s linear infinite alternate;
          animation: skeleton-border-glow 1s linear infinite alternate;
}

.border-light {
  border-color: #f5f5f5;
}

.border-width-2 {
  border-width: 2px;
}

.no-results {
  border: 1px dashed #ddd;
}

.skeleton-line {
  background: rgba(206, 217, 224, 0.2);
  height: 1em;
  border-radius: 4px;
  -webkit-animation: skeleton-bg-glow 1s linear infinite alternate;
          animation: skeleton-bg-glow 1s linear infinite alternate;
}

@-webkit-keyframes skeleton-bg-glow {
  from {
    background: rgba(206, 217, 224, 0.2);
  }
  to {
    background: rgba(92, 112, 128, 0.2);
  }
}

@keyframes skeleton-bg-glow {
  from {
    background: rgba(206, 217, 224, 0.2);
  }
  to {
    background: rgba(92, 112, 128, 0.2);
  }
}

@-webkit-keyframes skeleton-border-glow {
  from {
    border-color: rgba(206, 217, 224, 0.2);
  }
  to {
    border-color: rgba(92, 112, 128, 0.2);
  }
}

@keyframes skeleton-border-glow {
  from {
    border-color: rgba(206, 217, 224, 0.2);
  }
  to {
    border-color: rgba(92, 112, 128, 0.2);
  }
}

